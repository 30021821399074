import styled from "styled-components";
import { device } from "./device";

export const LoginGridCentered = styled.div`
  display: grid;
  max-height: 100vh;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: 100vh;
`;

export const LoginGrid = styled.div`
  display: grid;
  max-height: 100vh;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: 100vh;

  @media ${device.tablet} {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }

  @media ${device.laptop} {
    grid-template-columns: minmax(0, 2fr) minmax(0, 3fr);
  }
`;

export const Message = styled.div`
  color: ${(props) => props.theme.colors.error};
`;
export const LoginPassword = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  a,
  p {
    font-size: 16px !important;
  }
`;
export const LoginForm = styled.div`
  margin: auto;
  max-width: 500px;
  padding: 10px 16px 64px 16px;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    padding: 0 32px;
  }

  .login-header {
    font-size: 48px;
    font-weight: 800;
    line-height: 64px;
  }

  h2 {
    margin-top: 16px;
    margin-bottom: 32px;
    text-align: left;

    span {
      color: ${(props) => props.theme.colors.primary};
    }
  }
  p {
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 32px;
    margin-top: 0;
    line-height: normal;
  }
`;
export const LoginContainer = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 15px;
  align-items: center;
  margin-bottom: 32px;

  @media ${device.tablet} {
    margin-bottom: 64px;
  }
  p,
  hr {
    margin: 0 !important;
  }
  hr {
    border-color: #e5e5e5;
  }
`;
