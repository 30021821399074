import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { Auth } from "aws-amplify";
import {
  ButtonSizes,
  SocialButton,
  SocialButtonType,
} from "../../../../../../shared/styles/button";
import GoogleSVG from "/public/svg/talentpool/social/buttons/google.svg";

// To federated sign in from Google
export const SignInWithGoogle = ({ loading }) => {
  const signIn = () => {
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    }).then(() => {
      // console.log('HELLO');
    });
  };
  return (
    <SocialButton
      onClick={signIn}
      disabled={loading}
      $loading={loading}
      social={SocialButtonType.google}
      size={ButtonSizes.m}
      type="button"
    >
      <GoogleSVG />
      Anmeldung mit Google
    </SocialButton>
  );
};
